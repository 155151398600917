<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>终端IP地区</i>
          <el-input
            v-model="query.ipword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="终端IP地区"
          ></el-input>
        </div>
        <div class="item">
          <i>产品类型</i>
          <el-select
            v-model="query.productId"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="v in payproductsDrop"
              :label="v.productName"
              :value="v.productId"
              :key="v.productId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
          <el-button
            v-auth="'ACCOUNT:IP:SETTING/ADD'"
            @click="addTab"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>商户名称</p>
          </td>
          <td>
            <p>商户编号</p>
          </td>
          <td>
            <p>产品类型</p>
          </td>
          <td>
            <p>终端IP地区</p>
          </td>
          <td>
            <p>店铺名称</p>
          </td>
          <td>
            <p>报备商户</p>
          </td>
          <td>
            <p>报备商户号</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.mchName }}</p>
          </td>
          <td>
            <p>{{ v.mchId }}</p>
          </td>
          <td>
            <p>{{ v.productName }}</p>
          </td>
          <td>
            <p>{{ v.pName }}{{ v.cName ? "/" + v.cName : "" }}</p>
          </td>
          <td>
            <p>{{ v.storeName }}</p>
          </td>
          <td>
            <p>{{ v.tradeMchName }}</p>
          </td>
          <td>
            <p>{{ v.tradeMchNo }}</p>
          </td>
          <td style="width: 66px">
            <p>
              <a
                v-auth="'ACCOUNT:IP:SETTING/EDIT'"
                href="javascript:;"
                @click="showTab(v)"
                class="lianjie"
                >编辑</a
              >
              <a
                v-auth="'ACCOUNT:IP:SETTING/DELETE'"
                href="javascript:;"
                @click="delTab(v)"
                class="lianjie"
                >删除</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
    <el-dialog title="新增终端IP配置" :visible.sync="mchShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>配置商户</div>
          <el-select
            v-model="mchId"
            placeholder="选择配置商户"
            class="width100"
          >
            <el-option
              :label="v.mchName"
              :value="v.mchId"
              v-for="v in mchDrop"
              :key="v.mchId"
            ></el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="addBt1" @click="addclickHander">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ipConfigList, delIpConfig } from "@/api/user/ip.js";
import { payproductsDrop, merchantsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        enabledStatus: "",
        keyword: "",
        ipword: "",
        productId:""
      },
      totalNum: 0,
      tabData: [],
      payproductsDrop: [],
      mchShow: false,
      mchId: "",
      mchDrop: [],
      payDropLists: {
        "trade.weixin.native": "微信扫码支付",
        "trade.alipay.native": "支付宝扫码支付",
        "trade.weixin.h5pay": "微信H5支付",
        "trade.weixin.jspay": "微信公众号支付",
        "trade.qqpay.native": "QQ钱包扫码支付",
        "trade.baidu.native": "百度扫码支付",
        "trade.weixin.wappay": "微信wap支付",
        "trade.qqpay.h5pay": "QQ钱包H5支付",
        "trade.unionpay.native": "银联扫码支付",
        "trade.alipay.h5pay": "支付宝H5支付",
        "trade.weixin.mppay": "微信小程序支付",
        "trade.weixin.apppay": "微信APP支付",
        "trade.weixin.apppay2": "微信APP+支付",
        "trade.weixin.micropay": "微信刷卡支付",
        "trade.alipay.micropay": "支付宝刷卡支付",
        "trade.jdpay.micropay": "京东刷卡支付",
        "trade.jdpay.native": "京东扫码支付",
        "trade.jdpay.apppay": "京东APP支付",
        "trade.jdpay.h5pay": "京东H5支付",
        "trade.unionpay": "银联刷卡支付",
        "trade.unionpay.apppay": "银联APP支付",
        "trade.unionpay.h5pay": "银联H5支付",
        "trade.suning.native": "苏宁扫码支付",
        fastpay: "快捷协议支付",
        directpay: "直接支付",
      },
    };
  },
  computed: {
    ...mapState({
      ipQueryDetail: "user_ipSettingQuery",
    }),
  },
  created() {
    if (this.ipQueryDetail) {
      this.query = this.ipQueryDetail.query;
    }
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setIpQuery: "user_setIpSettingQuery",
    }),
    //新增按钮
    addclickHander() {
      if(!this.mchId){
        this.$message.error("请选择配置商户");
        return;
      }
      this.mchShow = false;
      let obj = this.mchDrop.find((obj) => obj.mchId == this.mchId);
      this.$router.push({
        name: "ip_setting_add",
        query: {
          mchId: this.mchId,
          mchName: obj.mchName,
        },
      });
    },
    getDrop() {
      payproductsDrop().then((res) => {
        if (res) {
          this.payproductsDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      ipConfigList(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.mchIpList;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //删除
    delTab(value) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delIpConfig(value).then((res) => {
            if (res) {
              this.getLists();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addTab() {
      this.mchShow = true;
    },
    // 查看
    showTab(value) {
      this.$router.push({
        name: "ip_setting_add",
        query: {
          id: value.id,
          mchId: value.mchId,
          mchName: value.mchName,
          pageType: "edit",
        },
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setIpQuery(data);
  },
};
</script>
<style scoped>.con_from .lineP .item i {
  width: 74px;
}
.con_from .lineP .itemTime{width: 48.284%;}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 74px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 74px - 24.67px - 10px) / 2);
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  width: 200px;
  margin: 0 auto;
}

.addBt1 {
  width: 60px;
  height: 32px;
  background-color: #48b8b6 !important;
  border-color: #66b2af !important;
  color: #ffffff !important;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
/deep/.el-dialog__footer {
  overflow: hidden;
}
</style>
