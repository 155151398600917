import axios from '@/utils/request'

// ip制导列表
export function ipConfigList(params) {
  // params.loading = true
  return axios({
    url: `/manager/ip/config/merchants/ipconfigs`,
    method: 'get',
		params
  })
}
// ip制导删除
export function delIpConfig(data){
  return axios({
    url: `/manager/ip/config/merchants/${data.mchId}/ipconfig/${data.id}`,
    method: 'delete'
  })
}
// ip制导产品
export function getIpProducts(mchId) {
  return axios({
    url: `/manager/account/merchants/${mchId}/ip/products`,
    method: 'get'
  })
}
// ip制导详情
export function getIpDetail(mchId,id) {
  return axios({
    url: `/manager/ip/config/merchants/${mchId}/ipconfig/detail/${id}`,
    method: 'get'
  })
}
// 修改 ip制导
export function setIpConfig(mchId, data) {
  return axios({
    url: `/manager/ip/config//merchants/${mchId}/ipconfig`,
    method: 'post',
		data
  })
}
// ip制导商户店铺列表
export function getMchList(mchId) {
  return axios({
    url: `/manager/account/stores/${mchId}/list`,
    method: 'get'
  })
}